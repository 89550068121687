<template>
	<section class="payment">
		<div class="container">
			<Breadcrumb class="payment__crumb" :pages="pages" />

			<h1 class="payment__title">{{ $t('other.paymentMethod') }}</h1>
			<div class="payment__methods">
				<div class="payment__methods-item">
					<img
						src="@/assets/img/svg/payment/Swift.svg"
						alt="payment"
						decoding="async"
						width="32"
						height="32"
					/>
					<p>{{ $t('other.swift') }}</p>
				</div>
				<div class="payment__methods-item">
					<img
						src="@/assets/img/svg/payment/Crypto.svg"
						alt="payment"
						decoding="async"
						width="32"
						height="32"
					/>
					<p>{{ $t('other.crypto') }}</p>
				</div>
				<div class="payment__methods-item">
					<img
						src="@/assets/img/svg/payment/Cash.svg"
						alt="payment"
						decoding="async"
						width="32"
						height="32"
					/>
					<p>{{ $t('other.cash') }}</p>
				</div>
			</div>
			<!-- <div class="payment__gates">
				<a
					target="_blank"
					href="https://t.me/seven_gates_ai_bot?start=7G-KUQ3DW"
					class="payment__gates-link"
				>
					<img
						src="@/assets/img/svg/payment/Gates.svg"
						alt="7 gates exchange"
						decoding="async"
						width="120"
						height="35"
					/>
				</a>
				<p>{{ $t('other.aboutGates') }}</p>
			</div> -->
			<div class="payment__map">
				<div
					id="mapbox-payment"
					class="mapbox-payment"
					style="height: 250px; border-radius: 4px"
				/>
				<p>
					One Palm, one of the most luxurious projects in the Dubai market,
					presents luxurious ultra-modern business class apartments. One Palm
					has 23 floors and includes 94 residences ranging from 239 to 2778
					square meters. m, created by the best architects and designers in the
					world: apartments with 3 and 4 bedrooms and penthouses with 5
					bedrooms. All apartments have a view of the Persian Gulf and the
					skyline of Dubai.
				</p>
			</div>
		</div>
	</section>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import { setCommonMetaData } from '@/utils/setMetaData'
import { i18n } from '@/i18n'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

export default {
	name: 'Payment',
	setup() {
		setCommonMetaData(`${i18n.global.t('titlePages.payment')} - OWRealty`, {
			description: i18n.global.t('meta.paymentDesc'),
			type: 'article',
			title: i18n.global.t('meta.paymentTitle'),
			url: window.location.href
		})
	},
	components: { Breadcrumb },
	data() {
		return {
			map: null
		}
	},
	mounted() {
		if (!mapboxgl) {
			return
		}

		mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN

		this.map = new mapboxgl.Map({
			container: 'mapbox-payment',
			style: 'mapbox://styles/mapbox/light-v11',
			center: [55.2707828, 25.2048493],
			zoom: 10
		})

		// Disable rotate
		this.map.dragRotate.disable()
		this.map.touchZoomRotate.disableRotation()

		this.map.on('load', () => {
			this.map.addSource('places', {
				type: 'geojson',
				data: {
					type: 'FeatureCollection',
					features: this.content.map((itm) => ({
						type: 'Feature',
						properties: {
							description: itm.address
						},
						geometry: {
							type: 'Point',
							coordinates: [itm.lat, itm.lng]
						}
					}))
				}
			})

			// Выставляем центра на основе точек
			const bounds = new mapboxgl.LngLatBounds()
			this.content.forEach((itm) => {
				bounds.extend([itm.lat, itm.lng])
			})
			this.map.fitBounds(bounds, { padding: 50 })

			this.content.forEach((itm) => {
				const marker = document.createElement('div')
				marker.className = 'payment-marker'
				marker.style.backgroundImage = 'url(/images/mapbox-payment.png)'
				marker.style.width = '30px'
				marker.style.height = '30px'
				marker.style.backgroundSize = '100%'

				const popup = new mapboxgl.Popup({ offset: 25 }).setText(itm.address)

				marker.addEventListener(
					'click',
					(e) => {
						const instanceMarker = e.target
						instanceMarker.classList.add('marker-open')

						popup.on('close', () => {
							instanceMarker.classList.remove('marker-open')
						})
					},
					{ passive: true }
				)

				new mapboxgl.Marker(marker)
					.setLngLat([itm.lat, itm.lng])
					.setPopup(popup)
					.addTo(this.map)
			})

			this.map.on('mouseenter', 'places', () => {
				this.map.getCanvas().style.cursor = 'pointer'
			})

			this.map.on('mouseleave', 'places', () => {
				this.map.getCanvas().style.cursor = ''
			})
		})
	},
	computed: {
		content() {
			return [
				{
					lat: 55.140426,
					lng: 25.077312,
					address: 'Address Dubai Marina, 66 Al Marsa St, Dubai Marina, Dubai'
				},
				{
					lat: 55.265048,
					lng: 25.206334,
					address: 'City Walk, Happiness street, 2A, Dubai'
				}
			]
		},
		pages() {
			return {
				first: {
					title: this.$t('general.home'),
					srcName: 'MainRoute'
				},
				current: this.$t('other.paymentMethod')
			}
		}
	}
}
</script>

<style lang="scss">
.mapbox-payment {
	& .mapboxgl-popup {
		max-width: 470px !important;

		&.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
			border-bottom-color: #574dd3;
		}

		&.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
			border-top-color: #574dd3;
		}

		&.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
			border-right-color: #574dd3;
		}

		&.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
			border-left-color: #574dd3;
		}
	}

	& .mapboxgl-popup-content {
		padding: 10px;
		background-color: #574dd3;
		border-radius: 4px;

		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		color: #ffffff;

		& .mapboxgl-popup-close-button {
			display: none;
		}
	}

	.payment-marker {
		display: block;
		border: none;
		border-radius: 50%;
		cursor: pointer;
		padding: 0;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			width: 22px;
			height: 22px;
			margin: auto;
			background: #574dd3;
			border: 1px solid #ffffff;
			border-radius: 50%;
			opacity: 0;
			transition: opacity 0.5s linear;
		}

		&.marker-open {
			position: relative;

			&:before {
				opacity: 1;
				transition: opacity 0.5s linear;
			}
		}
	}
}
</style>

<style lang="scss">
.payment {
	margin-bottom: 50px;

	&__crumb {
		margin-bottom: 30px;
	}

	&__title {
		margin: 0;
		margin-bottom: 30px;
		padding-bottom: 33px;
		font-weight: 700;
		font-size: 28px;
		line-height: 120%;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);

		@media screen and (max-width: 479px) {
			padding-bottom: 25px;
			font-size: 24px;
			line-height: 33px;
		}
	}

	&__methods {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		margin-bottom: 20px;
	}

	&__methods-item {
		height: 102px;
		background: rgba(0, 0, 0, 0.02);
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 10px;

		p {
			margin: 0;
			font-size: 14px;
		}
	}

	&__gates {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		margin-bottom: 30px;
		p {
			font-size: 12px;
			line-height: 16px;
			color: #808080;
		}
	}

	&__gates-link {
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 102px;
		width: 100%;

		background-image: url('~@/assets/img/pages/payment-bg.webp');
		background-size: cover;
	}

	&__map {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 20px;
		align-items: center;
		p {
			font-weight: 400;
			font-size: 14px;
			line-height: 21px;
			color: #4d4d4d;
			margin: 0;
		}
	}

	@media screen and (max-width: 620px) {
		&__map {
			grid-template-columns: 1fr;
		}
	}

	@media screen and (max-width: 479px) {
		&__methods {
			gap: 10px;
		}

		&__gates {
			margin-bottom: 20px;
		}
	}
}
</style>
